import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Dropdown, Icon, message } from 'antd';
const { Header } = Layout;
import { history, cache, util } from 'qmkit';
import QRCode from 'qrcode';

export default class MyHeader extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const loginInfo = JSON.parse(sessionStorage.getItem(cache.LOGIN_DATA));
    if (!loginInfo) {
      return null;
    }

    const accountName = loginInfo && loginInfo.accountName;
    const isMasterAccount = loginInfo && loginInfo.isMasterAccount;
    const mobile = loginInfo && loginInfo.mobile;

    //解析sessionStorage中的baseConfig
    let baseConfig,
      h5Dom,
      miniProgramDom,
      pcDom = null;
    if (sessionStorage.getItem(cache.SYSTEM_BASE_CONFIG)) {
      baseConfig = JSON.parse(sessionStorage.getItem(cache.SYSTEM_BASE_CONFIG));
    }

    //获取程序二维码
    let miniProgramUrl = localStorage.getItem(cache.MINI_QRCODE);
    if (miniProgramUrl) {
      miniProgramDom = (
        <Menu.Item key="2">
          <a href="javascript:;" className="previewCode">
            <img src={miniProgramUrl} alt="二维码" width="80" height="80" />
            <p>小程序</p>
            <div className="previewImg">
              <img src={miniProgramUrl} alt="二维码"/>
            </div>
          </a>
        </Menu.Item>
      );
    }

    if (baseConfig) {
      if (baseConfig['pcWebsite']) {
        let url = '';
        if (baseConfig['pcWebsite'].endsWith('/')) {
          url = `${baseConfig['pcWebsite']}store-main/${loginInfo.storeId}`;
        } else {
          url = `${baseConfig['pcWebsite']}/store-main/${loginInfo.storeId}`;
        }
        let pcCodeUrl =
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0E2NTM4MzMzRjQxMUU5QTM5MUY5ODk5ODhCQTBCMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0E2NTM4NDMzRjQxMUU5QTM5MUY5ODk5ODhCQTBCMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkNDQTY1MzgxMzNGNDExRTlBMzkxRjk4OTk4OEJBMEIzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNDQTY1MzgyMzNGNDExRTlBMzkxRjk4OTk4OEJBMEIzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Ira84wAAAaBJREFUeNrs3LFKw1AYxXGjteIkqLOoOHVwin0EQUFBF0FXFzfRx3BwqYIPIDjoIiKCr5BB8QHUXYQuClWJp3AHh1Rpemvycf8HDoGE3jQ/btKElkZpmg6Q/BmEAEAAAQSQAAgggAASAAEEEEACIIAAAkgABNBCKj4Hi+O49AecJAkzMIRTeFK9VFtqWmA/1At1wsQp/CPH6kpJLlHrbqKsWQJcdMtX9bFAwBl1XF22NgPH3PJG3SoQ8FTdVKvcxnAfCCABEEAAASQAAggggARAAAEEkAAIIIC2U+nz+HX1pMDjq1sHnHPNk/bXkk/qtBpxCneffXVW3Q3xGtj0MMadW957GKtlDfDWwxgNN/saHsa6tnYN3FFH1KUe9lFTD3t8H5/qlbptDfBFXc352in1OWP9vPrAhwg30gRAQzfSnbKnbqhDGds6/RDoTH3PWP+lnqsHoQCOuoPtdvbXftm2oB6pbyGcwtU+7Lf9qDccygxslvnZlg+Rf07EXz8xAwEEEEACIIAAAkgABBBAAAmAAAIIIAEQQAABJH/lW4ABAOc7TB5B8zqiAAAAAElFTkSuQmCC';
        pcDom = (
          <Menu.Item key="0">
            <a target="_blank" href={url}>
              <div className="firstBorder">
                <img src={pcCodeUrl} alt="PC" width="80" height="80" />
              </div>
              <p style={{paddingRight: 15}}>PC端</p>
            </a>
          </Menu.Item>
        );
      }
      if (baseConfig['mobileWebsite']) {
        let url = '';
        if (baseConfig['mobileWebsite'].endsWith('/')) {
          url = `${baseConfig['mobileWebsite']}store-main/${loginInfo.storeId}`;
        } else {
          url = `${baseConfig['mobileWebsite']}/store-main/${
            loginInfo.storeId
          }`;
        }
        let qrCodeUrl = null;
        QRCode.toDataURL(url, { errorCorrectionLevel: 'H' }, function(
          _err,
          url
        ) {
          qrCodeUrl = url;
        });
        h5Dom = (
          <Menu.Item key="1">
            <a target="_blank" href={url} className="previewMobile">
              <img src={qrCodeUrl} alt="二维码" width="80" height="80" />
              <p>移动端</p>
              <div className="previewImg">
                <img src={qrCodeUrl} alt="二维码"/>
              </div>
            </a>
          </Menu.Item>
        );
      }
    }

    let menuPreview = null;
    if (pcDom || h5Dom || miniProgramDom) {
      menuPreview = (
        <Menu className="menuPreview">
          {pcDom && pcDom}
          {h5Dom && h5Dom}
          {miniProgramDom && miniProgramDom}
        </Menu>
      );
    } else {
      menuPreview = (
        <Menu className="menuPreview">
          <Menu.Item key="1">
            <a>请先在基本设置中配置预览链接</a>
          </Menu.Item>
        </Menu>
      );
    }

    const menu = (
      <Menu>
        {isMasterAccount == '1' ? (
          <Menu.Item key="0">
            <Link to={'/account-manage'}>
              <Icon type="user" /> 账户管理
            </Link>
          </Menu.Item>
        ) : null}

        <Menu.Item key="1">
          <a
            href="javascript:;"
            onClick={() =>
              mobile && mobile != ''
                ? history.push({
                    pathname: '/find-password',
                    state: {
                      phone: mobile
                    }
                  })
                : message.error('请先绑定手机号')
            }
          >
            <Icon type="lock" /> 修改密码
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="javascript:;" onClick={() => this._handleLogout()}>
            <Icon type="logout" /> 退出
          </a>
        </Menu.Item>
        <Menu.Divider />
      </Menu>
    );

    return (
      <Header className="header">
        <div style={styles.headerLeft}>
          <a href="/" style={styles.logoBg}>
            <img
              style={styles.logoImg}
              src={
                sessionStorage.getItem(cache.SITE_LOGO)
                  ? sessionStorage.getItem(cache.SITE_LOGO)
                  : util.requireLocalSrc('sys/02.jpg')
              }
            />
          </a>
          {baseConfig && (
            <Dropdown overlay={menuPreview} trigger={['click']}>
              <a className="ant-dropdown-link" href="javascript:;">
                &nbsp;<Icon type="eye-o" />&nbsp;预览&nbsp;
              </a>
            </Dropdown>
          )}
        </div>
        <div>
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" href="javascript:;">
              <Icon type="user" /> {accountName} <Icon type="down" />
            </a>
          </Dropdown>
        </div>
      </Header>
    );
  }

  /**
   * 退出登录
   */
  _handleLogout = () => {
    util.logout();
  };
}

const styles = {
  logoBg: {
    width: 140,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoImg: {
    display: 'block',
    width: 120,
    maxHeight: 40
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
} as any;
