const routes = [
  //首页
  { path: '/', exact: true, asyncComponent: () => import('./home') },
  //订单列表
  {
    path: '/order-list',
    exact: true,
    asyncComponent: () => import('./order-list')
  },
  //分销记录
  {
    path: '/distribution-record',
    exact: true,
    asyncComponent: () => import('./distribution-record')
  },
  //订单-新增/编辑
  {
    path: '/order-edit/:tid',
    exact: true,
    asyncComponent: () => import('./order-add')
  },
  {
    path: '/order-add',
    exact: true,
    asyncComponent: () => import('./order-add')
  },
  //订单-详情
  {
    path: '/order-detail/:tid',
    exact: true,
    asyncComponent: () => import('./order-detail')
  },
  //订单-退单列表
  {
    path: '/order-return-list',
    exact: true,
    asyncComponent: () => import('./order-return-list')
  },
  //订单-退单列表-发起售后申请
  {
    path: '/order-return-apply-list',
    exact: true,
    asyncComponent: () => import('./order-return-apply-list')
  },
  //订单-订单管理-订单列表-新增退单
  {
    path: '/order-return-add',
    exact: true,
    asyncComponent: () => import('./order-return-add')
  },
  //订单-订单管理-退单列表-修改退单
  {
    path: '/order-return-edit/:rid',
    exact: true,
    asyncComponent: () => import('./order-return-edit')
  },
  //订单-订单管理-退单详情
  {
    path: '/order-return-detail/:rid',
    exact: true,
    asyncComponent: () => import('./order-return-detail')
  },
  //印染服务订单
  {
    path: '/service-order-list',
    exact: true,
    asyncComponent: () => System.import('./service-order')
  },
  //印染服务订单-详情
  {
    path: '/service-order-detail/:tid',
    exact: true,
    asyncComponent: () => System.import('./service-order-detail')
  },
  //已入库批次
  {
    path: '/service-order-production',
    exact: true,
    asyncComponent: () => System.import('./service-order-production')
  },
  //财务-收款账户
  {
    path: '/finance-account-receivable',
    asyncComponent: () => import('./finance-account-receivable')
  },
  //订单收款
  {
    path: '/finance-order-receive',
    asyncComponent: () => import('./finance-order-receive')
  },
  //退单退款
  {
    path: '/finance-refund',
    asyncComponent: () => import('./finance-refund')
  },
  //收款详情
  {
    path: '/finance-receive-detail',
    asyncComponent: () => import('./finance-receive-detail')
  },
  //退款明细
  {
    path: '/finance-refund-detail',
    asyncComponent: () => import('./finance-refund-detail')
  },
  //增值税资质审核
  {
    path: '/finance-val-added-tax',
    asyncComponent: () => import('./finance-val-added-tax')
  },
  //订单开票
  {
    path: '/finance-order-ticket',
    asyncComponent: () => import('./finance-order-ticket')
  },
  //财务-开票管理
  {
    path: '/finance-ticket-manage',
    asyncComponent: () => import('./finance-ticket-manage')
  },
  // 员工列表
  {
    path: '/employee-list',
    asyncComponent: () => import('./employee-list')
  },
  // 权限分配
  {
    path: '/authority-allocating',
    asyncComponent: () => import('./authority-allocating')
  },
  // 店铺分类
  { path: '/goods-cate', asyncComponent: () => import('./goods-cate') },
  // 详情模板
  {
    path: '/goods-detail-template',
    asyncComponent: () => import('./goods-detail-tab')
  },
  // 商品添加
  {
    path: '/goods-add', asyncComponent: () => import('./goods-add')
  },
  // 服务商品添加
  {
    path: '/goods-service-add', asyncComponent: () => import('./goods-service-add')
  },
  // 服务商品列表
  {
    path: '/goods-service-list', asyncComponent: () => import('./goods-service-list')
  },
  // 审核通过的商品编辑
  {
    path: '/goods-edit/:gid',
    asyncComponent: () => import('./goods-add')
  },
  // 审核通过的印染服务商品编辑
  {
    path: '/goods-service-edit/:gid',
    asyncComponent: () => import('./goods-service-add')
  },
  // 审核不通过的商品编辑
  {
    path: '/goods-check-edit/:gid',
    asyncComponent: () => import('./goods-add')
  },
  // 审核不通过的印染服务商品编辑
  {
    path: '/goods-service-check-edit/:gid',
    asyncComponent: () => import('./goods-service-add')
  },
  // 审核通过的商品sku编辑
  {
    path: '/goods-sku-edit/:pid',
    asyncComponent: () => import('./goods-sku-edit')
  },
  // 审核不通过的商品sku编辑
  {
    path: '/goods-sku-check-edit/:pid',
    asyncComponent: () => import('./goods-sku-edit')
  },
  // 商品详情
  {
    path: '/goods-detail/:gid',
    asyncComponent: () => import('./goods-detail')
  },
  // 商品详情SKU
  {
    path: '/goods-sku-detail/:pid',
    asyncComponent: () => import('./goods-sku-detail')
  },
  // 商品列表
  { path: '/goods-list', asyncComponent: () => import('./goods-list') },
  // 待审核商品列表
  {
    path: '/goods-check',
    asyncComponent: () => import('./goods-check')
  },
  // 客户列表
  {
    path: '/customer-list',
    asyncComponent: () => import('./customer-list')
  },
  // 客户等级
  {
    path: '/customer-level',
    asyncComponent: () => import('./customer-level')
  },
  // 基本设置
  {
    path: '/basic-setting',
    asyncComponent: () => import('./basic-setting')
  },
  // 店铺信息
  {
    path: '/store-info',
    asyncComponent: () => import('./shop/store-info-index')
  },
  //店铺信息编辑
  {
    path: '/store-info-edit',
    asyncComponent: () => import('./shop/store-info-edit')
  },
  // 图片库
  {
    path: '/picture-store',
    asyncComponent: () => import('./picture-store')
  },
  // 视频库
  {
    path: '/video-store',
    asyncComponent: () => import('./video-store')
  },
  // 图片分类
  {
    path: '/picture-cate',
    asyncComponent: () => import('./picture-cate')
  },
  // 素材分类
  {
    path: '/resource-cate',
    asyncComponent: () => import('./resource-cate')
  },
  // 账号管理
  {
    path: '/account-manage',
    asyncComponent: () => import('./account-manage')
  },
  // 物流公司管理
  {
    path: '/logistics-manage',
    asyncComponent: () => import('./logistics-manage')
  },
  // 商品导入
  {
    path: '/goods-import',
    asyncComponent: () => import('./goods-import')
  },
  //资金管理-财务对账
  {
    path: '/finance-manage-check',
    asyncComponent: () => import('./finance-manage-check')
  },
  //资金管理-财务对账-明细
  //{ path: '/finance-manage-refund/:sid/income', asyncComponent: () => import('./finance-manage-refund') },
  //资金管理-财务结算
  {
    path: '/finance-manage-settle',
    asyncComponent: () => import('./finance-manage-settle')
  },
  //结算明细
  {
    path: '/billing-details/:settleId',
    asyncComponent: () => import('./billing-details')
  },
  //收款账户-商家收款账户
  {
    path: '/vendor-payment-account',
    asyncComponent: () => import('./vendor-payment-account')
  },
  //收款账户-商家收款账户-新增账号
  {
    path: '/vendor-new-accounts',
    asyncComponent: () => import('./vendor-new-accounts')
  },
  //开票管理-开票项目
  {
    path: '/finance-ticket-new',
    asyncComponent: () => import('./finance-ticket-new')
  },
  //发布商品
  {
    path: '/release-products',
    asyncComponent: () => import('./release-products')
  },
  //资金管理-财务对账-明细
  {
    path: '/finance-manage-refund/:sid/:kind',
    asyncComponent: () => import('./finance-manage-refund')
  },
  //流量统计
  {
    path: '/flow-statistics',
    asyncComponent: () => import('./flow-statistics')
  },
  //交易统计
  {
    path: '/trade-statistics',
    asyncComponent: () => import('./trade-statistics')
  },
  //商品统计
  {
    path: '/goods-statistics',
    asyncComponent: () => import('./goods-statistics')
  },
  //客户统计
  {
    path: '/customer-statistics',
    asyncComponent: () => import('./customer-statistics')
  },
  //业务员统计
  {
    path: '/employee-statistics',
    asyncComponent: () => import('./employee-statistics')
  },
  //报表下载
  {
    path: '/download-report',
    asyncComponent: () => import('./download-report')
  },
  //编辑营销-满赠
  {
    path: '/marketing-full-gift/:marketingId?',
    asyncComponent: () => import('./marketing-add/full-gift')
  },
  //编辑营销-满折
  {
    path: '/marketing-full-discount/:marketingId?',
    asyncComponent: () => import('./marketing-add/full-discount')
  },
  //新增 / 编辑营销-满减
  {
    path: '/marketing-full-reduction/:marketingId?',
    asyncComponent: () => import('./marketing-add/full-reduction')
  },
  //营销列表
  {
    path: '/marketing-list',
    asyncComponent: () => import('./marketing-list')
  },
  //营销-满赠详情
  {
    path: '/marketing-details/:marketingId',
    asyncComponent: () => import('./marketing-details')
  },
  //营销中心
  {
    path: '/marketing-center',
    asyncComponent: () => import('./marketing-center')
  },
  // 商品库导入
  {
    path: '/goods-library',
    asyncComponent: () => import('./goods-library')
  },
  // 运费模板
  { path: '/freight', asyncComponent: () => import('./freight') },

  // 新增店铺运费模板
  {
    path: '/store-freight',
    asyncComponent: () => import('./freight-store')
  },
  // 编辑店铺运费模板
  {
    path: '/store-freight-edit/:freightId',
    asyncComponent: () => import('./freight-store')
  },
  // 新增单品运费模板
  {
    path: '/goods-freight',
    asyncComponent: () => import('./freight-goods')
  },
  // 编辑单品运费模板
  {
    path: '/goods-freight-edit/:freightId',
    asyncComponent: () => import('./freight-goods')
  },
  // 运费模板关联商品
  {
    path: '/freight-with-goods/:freightId',
    asyncComponent: () => import('./freight-with-goods')
  },
  //在线客服
  {
    path: '/online-service',
    asyncComponent: () => import('./online-service')
  },
  //操作日志
  {
    path: '/operation-log',
    asyncComponent: () => import('./operation-log')
  },
  // 优惠券列表
  {
    path: '/coupon-list',
    asyncComponent: () => import('./coupon-list')
  },
  // 优惠券详情
  {
    path: '/coupon-detail/:cid',
    asyncComponent: () => import('./coupon-detail')
  },
  // 营销中心 - 创建优惠券
  {
    path: '/coupon-add',
    asyncComponent: () => import('./coupon-add')
  },
  // 营销中心 - 编辑优惠券
  {
    path: '/coupon-edit/:cid',
    asyncComponent: () => import('./coupon-add')
  },
  // 优惠券活动
  {
    path: '/coupon-activity-list',
    asyncComponent: () => import('./coupon-activity-list')
  },
  // 优惠券活动详情
  {
    path: '/coupon-activity-detail/:id/:type',
    asyncComponent: () => import('./coupon-activity-detail')
  },
  // 创建/编辑全场赠券活动
  {
    path: '/coupon-activity-all-present/:activityId?',
    asyncComponent: () => import('./coupon-activity-add/all-present')
  },
  //创建/编辑进店赠券活动
  {
    path: '/coupon-activity-store/:activityId?',
    asyncComponent: () => import('./coupon-activity-add/store')
  },
  //创建/编辑精准发券活动
  {
    path: '/coupon-activity-specify/:activityId?',
    asyncComponent: () => import('./coupon-activity-add/specify')
  },
  //分销设置
  {
    path: '/distribution-setting',
    asyncComponent: () => import('./distribution-setting')
  },
  //分销商品
  {
    path: '/distribution-goods-list',
    asyncComponent: () => import('./distribution-goods-list')
  },
  //商品分销素材列表
  {
    path: '/distribution-goods-matter-list',
    asyncComponent: () => import('./distribution-goods-matter-list')
  },
  //商品评价管理
  {
    path: '/goods-evaluate-list',
    asyncComponent: () => import('./goods-evaluate-list')
  },
  //积分订单列表
  {
    path: '/points-order-list',
    exact: true,
    asyncComponent: () => import('./points-order-list')
  },
  //积分订单详情
  {
    path: '/points-order-detail/:tid',
    exact: true,
    asyncComponent: () => import('./points-order-detail')
  },
  //拼团活动列表
  {
    path: '/groupon-activity-list',
    asyncComponent: () => import('./groupon-activity-list')
  },
  // 添加拼团活动
  {
    path: '/groupon-add',
    asyncComponent: () => import('./groupon-add')
  },
  // 编辑拼团活动
  {
    path: '/groupon-edit/:activityId',
    asyncComponent: () => import('./groupon-add')
  },
  // 拼团活动详情
  {
    path: '/groupon-detail/:activityId',
    asyncComponent: () => import('./groupon-detail')
  },
  //添加秒杀商品
  {
    path: '/add-flash-sale/:activityDate/:activityTime',
    exact: true,
    asyncComponent: () => import('./flash-sale-goods-add')
  },
  //秒杀活动列表
  {
    path: '/flash-sale-list',
    exact: true,
    asyncComponent: () => import('./flash-sale')
  },
  //秒杀商品列表
  {
    path: '/flash-sale-goods-list/:activityDate/:activityTime',
    exact: true,
    asyncComponent: () => import('./flash-sale-goods-list')
  },
  //提货订单列表
  {
    path: '/pickup-order-list',
    exact: true,
    asyncComponent: () => import('./pickup-order-list')
  }
];

const homeRoutes = [
  { path: '/login', asyncComponent: () => import('./login') },
  {
    path: '/find-password',
    asyncComponent: () => import('./find-password')
  },
  {
    path: '/lackcompetence',
    asyncComponent: () => import('./lackcompetence')
  },
  {
    path: '/pay-help-doc',
    asyncComponent: () => import('./pay-help-doc')
  },
  // 运费模板计算公式说明
  {
    path: '/freight-instruction',
    asyncComponent: () => import('./freight-instruction')
  },
  //视频详情
  {
    path: '/video-detail',
    asyncComponent: () => import('./video-detail')
  }
];

// 审核未通过下的, 包括未开店
const auditDidNotPass = [
  //开店流程
  {
    path: '/shop-process',
    asyncComponent: () => import('./shop/process-index')
  },
  //店铺信息 审核中/审核未通过
  {
    path: '/shop-info',
    asyncComponent: () => import('./shop/info-index')
  },
  //编辑店铺信息 审核未通过编辑页面
  {
    path: '/shop-info-edit',
    asyncComponent: () => import('./shop/info-edit')
  }
];

export { routes, homeRoutes, auditDidNotPass };
